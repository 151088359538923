import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not found" />
    <Header siteTitle="Marine Serre Archive" />
    <div className="container">
      <h1 className="fs-large">This page has not been found.</h1>
    </div>
  </Layout>
)

export default NotFoundPage
